<template>
  <div class="page-header">
    <div class="page-header-menu" @click="show = true">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="9" width="20" height="2"></rect>
        <rect y="3" width="20" height="2"></rect>
        <rect y="15" width="20" height="2"></rect>
      </svg>
    </div>
    <img
      class="page-header-logo"
      @click="gotoUrl('/')"
      src="../assets/logo.png"
    />
    <div :class="`page-header-nav ${show && 'show'}`">
      <div class="page-header-close" @click="show = false">×</div>
      <a class="page-header-nav-item" href="/">首页</a>
      <a class="page-header-nav-item" href="/worldwide">全球网络</a>
      <a class="page-header-nav-item" href="/exhibitions">展会一览</a>
      <a-dropdown placement="bottomLeft">
        <div class="page-header-nav-item" style="line-height: 22px">
          场馆信息
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/venues/rimini"
              >
                里米尼展览中心
              </a>
            </a-menu-item>
            <a-menu-item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.riminipalacongressi.it/"
              >
                里米尼会议中心
              </a>
            </a-menu-item>
            <a-menu-item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/venues/vicenza/"
              >
                维琴察展览中心
              </a>
            </a-menu-item>
            <a-menu-item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.vicenzaconventioncentre.it/"
              >
                维琴察会议中心
              </a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <a class="page-header-nav-item" href="/news">新闻中心</a>
      <a class="page-header-nav-item" href="/job">联系我们</a>
      <template v-if="isMobile">
        <a
          class="page-header-nav-item"
          style="border-top: 0.5px solid rgba(153, 153, 153, 1)"
          href="https://www.iegexpo.it/it/"
          ><img
            src="https://www.iegexpo.it/media/mod_languages/images/it.gif"
            alt=""
          />
          Italiano
        </a>
        <a class="page-header-nav-item" href="https://www.iegexpo.it/en/"
          ><img
            src="https://www.iegexpo.it/media/mod_languages/images/en.gif"
            alt=""
          />
          English</a
        >
        <a class="page-header-nav-item" href="https://www.iegexpo.it/ar/"
          ><img
            src="https://www.iegexpo.it/media/mod_languages/images/ar_aa.gif"
            alt=""
          />
          اللغة العربية</a
        >
        <a class="page-header-nav-item" href="/"
          ><img
            src="https://www.iegexpo.it/media/mod_languages/images/zh_cn.gif"
            alt=""
          />
          简体中文</a
        >
        <a class="page-header-nav-item" href="https://www.iegexpo.it/fr/"
          ><img
            src="https://www.iegexpo.it/media/mod_languages/images/fr_fr.gif"
            alt=""
          />
          Français</a
        >
        <a class="page-header-nav-item" href="https://www.iegexpo.it/de/"
          ><img
            src="https://www.iegexpo.it/media/mod_languages/images/de_de.gif"
            alt=""
          />
          Deutsch</a
        >
        <a class="page-header-nav-item" href="https://www.iegexpo.it/pt/"
          ><img
            src="https://www.iegexpo.it/media/mod_languages/images/pt_br.gif"
            alt=""
          />
          Português</a
        >
        <a class="page-header-nav-item" href="https://www.iegexpo.it/es/"
          ><img
            src="https://www.iegexpo.it/media/mod_languages/images/es_es.gif"
            alt=""
          />
          Español</a
        >
      </template>
    </div>
    <a-dropdown>
      <a class="page-header-lang" @click.prevent>
        <CaretDownOutlined />
        简体中文
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item>
            <a href="https://www.iegexpo.it/it/"
              ><img
                src="https://www.iegexpo.it/media/mod_languages/images/it.gif"
                alt=""
              />
              Italiano
            </a>
          </a-menu-item>
          <a-menu-item>
            <a href="https://www.iegexpo.it/en/"
              ><img
                src="https://www.iegexpo.it/media/mod_languages/images/en.gif"
                alt=""
              />
              English</a
            >
          </a-menu-item>
          <a-menu-item>
            <a href="https://www.iegexpo.it/ar/"
              ><img
                src="https://www.iegexpo.it/media/mod_languages/images/ar_aa.gif"
                alt=""
              />
              اللغة العربية</a
            >
          </a-menu-item>
          <a-menu-item>
            <a href="/"
              ><img
                src="https://www.iegexpo.it/media/mod_languages/images/zh_cn.gif"
                alt=""
              />
              简体中文</a
            >
          </a-menu-item>
          <a-menu-item>
            <a href="https://www.iegexpo.it/fr/"
              ><img
                src="https://www.iegexpo.it/media/mod_languages/images/fr_fr.gif"
                alt=""
              />
              Français</a
            >
          </a-menu-item>
          <a-menu-item>
            <a href="https://www.iegexpo.it/de/"
              ><img
                src="https://www.iegexpo.it/media/mod_languages/images/de_de.gif"
                alt=""
              />
              Deutsch</a
            >
          </a-menu-item>
          <a-menu-item>
            <a href="https://www.iegexpo.it/pt/"
              ><img
                src="https://www.iegexpo.it/media/mod_languages/images/pt_br.gif"
                alt=""
              />
              Português</a
            >
          </a-menu-item>
          <a-menu-item>
            <a href="https://www.iegexpo.it/es/"
              ><img
                src="https://www.iegexpo.it/media/mod_languages/images/es_es.gif"
                alt=""
              />
              Español</a
            >
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>
<style lang="scss">
.page-header {
  height: 100px;
  position: relative;
  z-index: 2;
  background-color: #fff;
  &-menu {
    display: none;
  }
  &-logo {
    height: 100px;
    position: absolute;
    left: 30px;
    cursor: pointer;
  }
  &-nav {
    width: 504px;
    height: 100px;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    &-lang {
      display: none;
    }
    &-item {
      color: var(---001, #1a1a1a);
      text-align: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      cursor: pointer;
      text-decoration-line: none;
    }
  }
  &-lang {
    width: 100px;
    height: 24px;
    border: 1px solid #000;
    position: absolute;
    right: 30px;
    top: 38px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(---001, #1a1a1a);
    font-size: 14px;
    font-weight: 400;
    padding: 0 8px;
  }
  &-close {
    display: none;
  }
}
@media (max-width: 768px) {
  .page-header {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    position: fixed !important;
    &-logo {
      width: auto;
      height: 80px;
      position: relative;
      transform: translateX(-3px);
    }
    &-menu {
      width: 64px;
      height: 64px;
      display: flex;
      position: absolute;
      left: 0;
      top: 18px;
      padding: 20px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &-nav {
      transition: width 0.8s;
      position: fixed;
      top: 0;
      left: 0;
      width: 0;
      height: 100vh;
      background: #000;
      flex-direction: column;
      margin: 0;
      padding: 0;
      color: #fff;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 60px 0 0 0;
      display: flex;
      visibility: hidden;
      overflow-y: auto;
      &-lang {
        display: block;
      }
      &.show {
        visibility: visible;
        width: 80vw;
        .page-header-close {
          display: flex;
        }
        &::before {
          content: " ";
          display: block;
          width: 20vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.2);
          position: absolute;
          right: -20vw;
          top: 0;
        }
      }
      &-item {
        width: 100%;
        line-height: 46px !important;
        color: #fff;
        text-align: left;
        padding-left: 30px;
      }
    }
    &-close {
      display: none;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 30px;
      color: #fff;
    }
    &-lang {
      display: none;
    }
  }
}
</style>
<script setup>
import { ref } from "vue";
import { CaretDownOutlined } from "@ant-design/icons-vue";
const gotoUrl = (url) => {
  location.href = url;
};
const isMobile = ref(document.documentElement.clientWidth <= 768);
const show = ref(false);
</script>
