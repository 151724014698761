export default {
  news: [
    {
      cover: "1.png",
      title: "意大利展览集团（IEG）为维琴察展览中心新展馆奠基",
      desc: [
        "2024年12月12日上午，在维琴察展览中心，举行了庆祝新2.2万平方米展馆建设启动的仪式。",
        "IEG高层管理人员、当地机构以及黄金珠宝行业协会均出席了仪式。",
        "IEG总裁毛里齐奥·埃尔梅托表示：“这座城市、这片土地和相关利益方都支持我们投资中心改造的决定。”",
      ],
      subTitle:
        "维琴察，2024年12月12日——位于维琴察展览中心核心区域的新展馆现已奠基。在改造工程启动整整十个月后，意大利展览集团于今日上午举行了仪式，庆祝这座由汉堡GMP建筑设计事务所（由教授兼建筑师沃尔克温·马尔格主持设计）设计的2.2万平方米、两层建筑的施工阶段正式开始。仪式上，集团高层管理人员、机构代表以及国家黄金珠宝行业协会代表均出席。",
      body: [
        {
          title: "奠基仪式",
          desc: [
            "在维琴察教区副主教唐·詹帕奥洛·马尔塔的祝福后，沃尔克温·马尔格将扩建项目方案放入一个容器中，并交给IEG总裁毛里齐奥·埃尔梅托，后者将其放入混凝土块中。随后，埃尔梅托与马尔格，以及市长贾科莫·波萨迈、省长安德烈亚·纳尔丁和维琴察商会会长乔治·佐卡托一同用抹泥板为奠基石进行了象征性的敲击。代表IEG出席的还有总经理科拉多·佩拉波尼、运营总监兼IEG维琴察场地总监马里奥·韦斯科沃、首席企业运营官卡洛·科斯塔和首席商务官马尔科·卡尔尼埃洛。",
          ],
        },
        {
          title: "致辞",
          desc: [
            "IEG总裁毛里齐奥·埃尔梅托表示：“五十多年前，有远见的人为‘蜗牛’（指维琴察展览中心）奠基，希望改善他们的生活、商业和这片土地。今天，通过这个象征性的举动，我们正在通过建造一个更大、更先进、更强大的结构来延续这一初衷，以保持维琴察珠宝展在欧洲和国际珠宝行业的领先地位。不仅如此，这一新基础设施还将使我们能够扩展我们的活动日程和会议中心日程，使我们更具吸引力。我们感谢这座城市、这片土地和相关利益方对我们愿景的支持。”",
            "IEG首席执行官科拉多·佩拉波尼表示：“从基础设施方面提升维琴察展览中心的竞争力，意味着保持维琴察珠宝展在欧洲的领导地位及其在国际背景下的独特性。但这也意味着加强IEG为其珠宝展制定的国际化战略，以迪拜和新加坡为枢纽：为市场创造平台，进而对访问意大利的外国运营商产生积极影响。”",
            "汉堡GMP建筑设计事务所的扩建设计师沃尔克温·马尔格教授表示：“我们很荣幸能为意大利展览集团进行设计。自里米尼展览中心成立以来，我们已建立了长达25年的出色创意合作关系。维琴察正在建设新的展馆，并对展览中心周边环境进行改造，这对维琴察来说是一个好兆头。在此背景下，我们可以预见未来的展会业务将在建筑感知方面也变得越来越具有吸引力。”",
            "维琴察市长贾科莫·波萨迈表示：“展览中心新展馆奠基对这座城市来说是一个极其重要的信号。这证明了意大利展览集团愿意在维琴察投资，为维琴察展览中心和我们这座一直与展会紧密相连的城市的经济带来了光明的未来。因此，这传递出了维琴察正在持续成长和投资，并满怀信心地展望未来的理念。”",
            "维琴察省省长安德烈亚·纳尔丁表示：“维琴察正在经历一场基础设施革命，而新的展览中心将很好地融入一个面向未来的城市环境中。这次改造不仅仅是外观上的改变，更意味着提升展会区域，使维琴察珠宝展更具吸引力，并加强维琴察经济的特色。奠基是一个强烈的信号，我们也想向我们的企业家传达这一信号：我们面临着重要的挑战，但我们正在增强自身实力以应对这些挑战。”",
            "维琴察商会会长乔治·佐卡托表示：“意大利展览集团致力于改造，为维琴察珠宝展等带来了巨大机遇，这值得称赞。位于城市入口处的展览中心将成为维琴察欢迎访客的‘名片’。未来的高铁站点项目将进一步增强这一投资，商会和维琴察系统已下定决心并团结一致地推进该项目。”",
          ],
        },
        {
          title: "建设如期进行",
          desc: [
            "由意大利展览集团维琴察场地运营总监马里奥·韦斯科沃负责的维琴察展览中心扩建和改造工程“第二阶段”正在顺利进行，旨在建造一座现代化且功能齐全的新建筑，该建筑将位于之前2号和5号展厅所占用的区域。工程完全按照意大利展览集团设定的时间表进行。在拆除旧建筑和土地平整阶段之后，施工现场现已移交给土木工程公司，该公司正在进行打桩工作，以支撑后续的地基。2025年1月的维琴察珠宝展期间，地上施工将初见端倪，而在由意大利展览集团全球展览经理马泰奥·法苏拉负责的2025年9月维琴察珠宝展上，由于夏季将完成的建筑，人们将看到整个未完工的建筑。随后将继续进行系统安装和收尾工作，预计于2026年春季完成。",
          ],
        },
      ],
    },
    {
      cover: "2.png",
      title: "KEY – 国际能源展：以能源效率和未来能源赢得脱碳挑战",
      desc: [
        "意大利展览集团里米尼展览中心2025年3月5日至7日的基本活动日程已公布",
        "从氢能到人工智能、可再生能源、电动出行、城市转型以及工业和建筑环境效率：这些都是KEY25博览会的全部主题",
      ],
      subTitle:
        "2024年12月10日，里米尼（意大利）——研究、调研和观察，以及会议、论坛和辩论，旨在反思能源转型和能效的现在与未来，以不断加速通往脱碳的道路。由意大利展览集团（IEG）主办，是欧洲、非洲和地中海地区的重要参考点，KEY——国际能源展的技术科学委员会（由吉安尼·西尔维斯特里尼教授担任主席，由机构、工业行业协会、技术科学协会、组织和基金会组成）正在制定下一届活动的大会日程。该活动将于2025年3月5日至7日在里米尼展览中心举行。",
      body: [
        {
          title: "KEY25的核心会议日程",
          desc: [
            "ForumTech论坛：展会首日，即2025年3月5日星期三，意大利太阳能协会（Italia Solare Association）的ForumTech论坛将如期亮相KEY。本次活动汇聚了行业专家、研究人员、学者和专业人士，共同探讨光伏领域的最新成果和未来趋势，并探索提升光伏效率和将其融入现有基础设施的最新技术、创新材料和先进解决方案。",
          ],
        },
        {
          title: "拥有高效工业和建筑的智能可持续城市：",
          desc: [
            "从工业到体育，再到房地产的重建、公共行政的作用、“绿色建筑”《建筑能效指令》（EPBD）背景下的可持续建筑和建筑环境的能效。在KEY，能效将成为核心议题，为能源管理者、能源效率专家（EGEs）、公用事业公司、能源服务公司（ESCOs）、核查与监测平台提供商以及其他所有相关方提供机会，以交流最佳实践和案例研究，全面了解当前技术水平、障碍、机遇以及实现脱碳目标所需的行动。此外，KEY的会议还将重点分析城市向更强韧性转型的过程，探索尖端技术的作用，重点关注适用于未来城市环境的解决方案。",
          ],
        },
        {
          title: "能源行业的人工智能：",
          desc: [
            "KEY将首次通过会议和研讨会探讨人工智能（AI）在能源行业的可能应用，旨在展示当前可用的选项和已开展项目所取得的成果，同时促进对采用该技术所带来的机遇、风险、伦理问题和环境影响进行反思。在为期三天的活动中，FIRE将展示面向技术供应商和用户开展的一项调查结果。",
          ],
        },
        {
          title: "机遇、法规与许可下的能源转型：",
          desc: [
            "能源转型不仅能增强一个国家的能源自主性和安全性，还能促进新就业机会和技术增长。当前地缘政治形势的不确定性可能减缓这一进程并影响不断攀升的能源成本，这些成本必须得到控制。KEY安排的会议将邀请所有相关方参与，旨在找出能够使转型可持续的可行解决方案，特别关注监管框架、阻碍商业投资的关键问题以及地区（如撒丁岛）的作用，届时将通过米兰理工大学的一项研究成果进行介绍。",
          ],
        },
        {
          title: "港口与海上风能：",
          desc: [
            "意大利港口正发展成为地中海地区名副其实的枢纽，为风能增长提供支持。从意大利环境与能源安全部致力于通过在南部意大利建立一个服务于海洋可再生能源增长的物流平台来启动工业发展的重要阶段开始，KEY25安排的活动将聚焦风能，重点关注推动该领域取得显著进步的技术、能源储存以及由于实施了集成人工智能、增强现实和无人机的先进控制系统而实现的生产和维护优化。KEY25还将迎来Su.port——能源转型可持续港口的首次亮相，这是一场关于港口码头电气化和海上风力发电（特别是浮动式）发展的展览。",
          ],
        },
        {
          title: "氢能源的新视角：",
          desc: [
            "氢能谷、已开展或正在启动的区域绿色氢能供应链、非有机来源可再生燃料的可持续性（如电子燃料和氢能）以及氢能和新可持续能源载体在机场的整合。氢能源将成为KEY会议议程和意大利展览集团与德国汉诺威展览公司（HFI）旗下德国汉诺威展览公司意大利分公司设立的氢能及燃料电池展区支持的全新HYPE——氢能动力博览会中的主要角色之一。",
          ],
        },
        {
          title: "电动交通转型：",
          desc: [
            "现有技术及其采用趋势、公路和高速公路网络沿线的公共和私人基础设施，重点关注难以减排的领域，如中长途重型公路运输、海上和航空运输：KEY25议程将通过机构、公司和行业协会参与的专题讨论会和会议，从不同角度探讨广泛的交通转型话题。KEY还将是汽车生态系统转型观察站路演的一站。",
          ],
        },
        {
          title: "光伏与农业：",
          desc: [
            "双赢的协同作用：尽管农业光伏是生产可再生能源的一种关键且创新的解决方案，但它仍面临众多环境和景观障碍，这些障碍阻碍了其实施。在KEY上，旨在概述当前可用的解决方案，并通过深入研究可以促进采用该技术的政策、法规和战略，来探索开发可持续且与景观融为一体的农业光伏项目的最佳实践。",
          ],
        },
        {
          title: "可再生能源社区：",
          desc: [
            "一种良性循环模式：鉴于意大利首批可再生能源社区的诞生，可再生能源协会（RSE）和意大利环境联合会（Legambiente）将分别展示各自的测绘成果，旨在分析这一模式的普及情况，并了解主要的组织模式、使用最广泛的技术以及相关机构和组织的作用。Legambiente将颁发奖项，表彰在质量和团结项目实施方面表现最佳的可再生能源社区，并介绍其与京都俱乐部和零排放组织（AzzeroCO2）共同发起的BeComE项目的最佳实践。",
          ],
        },
        {
          title: "能源储存：",
          desc: [
            "实现供需平衡：储存系统（尤其是长期储存系统）对于以经济高效、可靠和可持续的方式加速向可再生能源转型至关重要，确保在电力需求高峰时段也能保障能源供应并及时响应。然而，仍面临一些挑战，如监管不确定性和高昂的前期成本。KEY25将通过安排的活动（包括发布第四版ANIE和RSE白皮书）来解决这些问题。",
          ],
        },
        {
          title: "非洲：",
          desc: [
            "绿色未来的关键参与者：非洲拥有巨大的自然资源和可再生能源禀赋，有潜力成为全球绿色未来的关键参与者。然而，尽管近几十年来取得了进展，但该大陆仍远未实现2030年议程的目标。非洲将不得不面对根本性的能源挑战，其中电气化、数字化和战略专业技能的发展将发挥核心作用。今年，KEY将在与可再生能源促进非洲发展基金会（RES4Africa Foundation）的传统会面中再次讨论这一问题。",
            "KEY25会议日程不断更新，可通过活动官网以下链接查阅：http://www.key-expo.com/eventi。",
            "此外，计划于2025年3月4日（星期二），即KEY前夕，在里米尼Palacongressi会议中心举行第二届KEY CHOICE——解锁PPA未来活动，这是由意大利展览集团与Elemens合作组织的KEY——能源转型博览会面向电力购买协议（PPA）的B2B活动。",
          ],
        },
      ],
    },
    {
      cover: "3.png",
      title: "里米尼国际健身展：国际健康盛会",
      desc: [
        "第十九届盛会定于2025年5月29日至6月1日举行，票务系统于12月6日正式开放",
        "里米尼国际健身展预计设立六大区域及多项新功能",
        "重点聚焦运动医学、物理治疗和康复科学，为健身和健康专业人士打造",
      ],
      subTitle:
        "2024年12月5日，意大利里米尼 —— 国际健康与健身领域的标杆性盛会——第十九届里米尼国际健身展将于2025年5月29日至6月1日在里米尼展览中心及里维埃拉沿线场馆。本次盛会由意大利展览集团（IEG）主办，是整个健康界标志性的聚会，将创新、商务、培训和娱乐融为一体，共同庆祝健康生活方式。",
      body: [
        {
          title: "一站式健康体验",
          desc: [
            "里米尼国际健身展不仅仅是一场贸易展览会，更是一个真正的国际枢纽，19万平方米的室内和室外展览空间内囊括了整个健康与健身领域。行业巨头齐聚一堂：从世界领先的健身器材制造商到健身房和行业协会，他们正越来越多地追求技术创新和新的训练方法。众多健康专业人士将聚焦运动医学、康复、物理治疗和长寿领域，以及营养专业人士，他们提供的营养品和补充剂将身体健康与个人护理相结合。补充活动还包括与健康界日益紧密相连的旅游和设计活动，以及专注于技术服装和生活方式的区域。里米尼健康盛会设有30个展厅，将提供多样化的体验，旨在满足专业人士、公司和访客的需求，打造独一无二的聚会、培训和灵感场所。",
          ],
        },
        {
          title: "2025年里米尼国际健身展六大区域：全面探索健康",
          desc: [
            "2025年里米尼国际健身展再次分为六个不同的主题区域，旨在满足健康与健身领域的各种需求和兴趣。盛会的核心是ACTIVE区域，参观者可以通过大师班、现场演示和与世界知名教练的见面，探索最新的训练趋势。充满肾上腺素的STEEL区域将再次吸引大量观众，13,000多平方米的空间专注于力量和表现类运动。健美、力量举、格斗运动、武术和健美文化将完美融合，辅以主要品牌支持的补剂深度探索。在展览中心对面，是FITNESS区域，它是技术创新、顶级健身器材、数字服务和健身房、水疗中心、医疗和运动中心解决方案的专属展示区。HEALTH区域将专注于健康和长寿：在即将到来的这一届中，将更加强调运动医学、物理治疗、康复科学和专业培训，营养品行业的发展尤为迅速，得益于其在消费者和专业运营商都感兴趣的健康和医疗领域的应用。对于那些寻求更完整体验的人来说，WELLNESS区域将提供360°的个人健康之旅，提供旨在提高生活质量的产品、服务和技术。FOODWELL将以营养为主题，占据更大的空间，展示健康食品的最新资讯、烹饪演示、品尝和专注于健康生活的课程。",
          ],
        },
        {
          title: "RiminiWellness OFF：在神奇之地感受能量与运动",
          desc: [
            "与里米尼市政府合作举办的RiminiWellness OFF活动将于2025年如期举行：这是一次健康社区激活活动，通过一系列举措将健康和运动融入里米尼的历史、文化和旅游景点之中。从海岸到城市公园，从海滨到迷人的水上广场，此次活动庆祝无障碍和包容性健康，强化了里米尼作为积极生活方式倡导者的角色。在第三届活动中，RiminiWellness OFF活动将再次努力吸引广泛而多样的观众，从儿童到成年人，从业余爱好者到专业运动员，让健康成为人人都能体验到的活动。",
          ],
        },
        {
          title: "12月6日起开始售票",
          desc: [
            "购票啦！12月6日起，2025届盛会的在线票务系统将正式开放。更多信息请访问www.riminiwellness.com。",
          ],
        },
      ],
    },
    {
      title: "IBE - 2024国际巴士客车展确认其在共享与互联出行未来领域的领导地位",
      desc: [
        "与2022年相比，参展商数量激增（+58%），专业观众人数翻倍（+90%），外国观众数量也显著增加。",
        "携手可持续发展基金会和欧洲交通出行研究学会，首次推出“未来出行之路”（Intermobility Future Ways）论坛。",
      ],
      body: [
        {
          title: "",
          desc: [
            "里米尼（意大利），2024年11月21日——意大利展览集团（IEG）关于公共交通及相关工业供应链的权威盛会——第十一届IBE国际巴士客车展今日在里米尼展览中心圆满落幕。",
            "与2022年相比，专业观众人数激增（+90%），参展商数量（+58%）和外国公司参展数量（+11%）也在31,560平方米（+32%）的展区内大幅提升。",
            "十辆巴士进行了试驾，三天内共有超过370人参与了至少一次试驾。在为期三天的40场会议和研讨会广受欢迎，吸引了超过150位演讲嘉宾参与。",
            "此次活动也引起了媒体的广泛关注，其中40%的记者来自国外，这进一步证明了IBE日益增长的国际吸引力。",
            "第十一届IBE已成为欧洲巴士和长途客车供应链领域的标杆生态系统，该领域日益倾向于通过创新的互联互通、一体化和共享出行路径来实现脱碳。",
            "2024年版的IBE首次推出了“未来出行之路”（Intermobility Future Ways）论坛，这是首个关于共享出行的国家论坛，由可持续发展基金会、共享出行国家观测站和欧洲交通出行研究学会联合创立并提供技术支持，旨在连接多样且互补的行业，共同制定法规、传播知识和最佳实践、推广新路径并为可持续互联出行提供新的商业模式。",
            "第十一届IBE的主要参展商包括：IVECO BUS、Irizar、Sitcar Mobility Vehicles、Scania、Volvo Buses、Otokar、MAN、Isuzu Bus Italia、King Long、Troiani、Gruppo D’Auria、INDCAR以及许多其他国际知名参与者。同时，未来出行之路的主要合作伙伴也参与其中：意大利国家铁路公司（RFI）、意大利国家铁路集团（Trenitalia）、德勤（Deloitte）、Nordcom、Corrente（TPER集团）和Lime。",
            "在IBE 2024期间，首届洛伦佐·卡尼奥尼可持续创新奖获奖者名单公布，该奖项旨在表彰意大利和国际在可持续创新领域的卓越表现。奖项分为两个类别，表彰了在创新区参展的公司和初创企业中最具创新性的项目，包括：意大利国家铁路公司（RFI）、Sono Motors GmbH、ZF Italia、Wayla Srl、S.D.S. Safety Driving Solutions Srl和NCM。",
            "IBE由意大利国家巴士和长途客车工业协会（AN.BTI Confcommercio）、意大利道路运输协会（ASSTRA）共同组织，并得到意大利基础设施和运输部、环境和能源安全部、艾米利亚-罗马涅大区、里米尼市政府和里米尼省、意大利存贷款银行、意大利全国市镇协会和欧盟委员会、意大利青年创新者国家协会（ANGI）的支持。",
            "IBE国际巴士客车展的下一届展会将于2026年11月24日至26日举行，但在此期间，将于2025年举办一系列预热活动，专注于未来出行之路论坛和IBE驾驶体验活动的新版。",
          ],
        },
      ],
    },
    {
      cover: "5.png",
      title: "2025 SIGEP WORLD Roadshow：在柏林，聚焦国际视野与新领域",
      desc: [
        "意大利展览集团向德国利益相关方及餐饮服务业专业人士介绍活动下一届盛况",
        "一场面向冰淇淋、咖啡、烘焙甜品及巧克力、面包烘焙行业，以及自今年起新增的披萨行业的国际商业盛会",
        "意大利展览集团食品与饮料部集团展览经理弗拉维亚·莫雷利表示：“1月我们将在传统的冰淇淋、咖啡、烘焙甜品及面包烘焙行业基础上，新增披萨这一互补领域。这是加强SIGEP WORLD在餐饮服务业定位的关键，尤其对于欧洲以外、消费快速增长的专业人士而言。”",
      ],
      body: [
        {
          title: "2024年11月14日，柏林——一场面向全球的路演活动拉开序幕。",
          desc: [
            "近日，意大利展览集团（IEG）与意大利对外贸易委员会（ITA）携手，在柏林意大利大使馆举办了SIGEP WORLD——国际手工冰淇淋、烘焙和咖啡展下一届的推介活动。该届展会将于2025年1月18日至22日在意大利里米尼展览中心举行。来自德国市场的机构及利益相关方出席了此次柏林活动，活动聚焦于SIGEP WORLD为国际餐饮服务业专业人士所提供的机遇。",
            "“演讲嘉宾分享了德国餐饮服务业如何适应全球变化的经验和见解，”意大利展览集团首席商务官马可·卡尔尼埃洛回顾道，“与会者一致认为，对于整个外出就餐领域而言，SIGEP WORLD是了解市场发展、预测趋势及与国际一流关键利益相关方建立关系的核心平台。”",
          ],
        },
        {
          title: "Circana的约亨·平斯克谈欧洲餐饮服务业现状",
          desc: [
            "在柏林，Circana公司食品服务业欧洲行业顾问高级副总裁约亨·平斯克介绍了欧洲餐饮服务业消费状况的市场数据。尽管销售点访问量有所下降，但根据9月份的数据，该领域支出较2019年增长了8%。Circana的预测证实了该领域的良好健康状况和发展潜力，预计未来两年消费平均年增长率将达到3.5%。增长最快的领域是烘焙甜品、冰淇淋和咖啡，这也是SIGEP WORLD展览产品的主要类别。",
          ],
        },
        {
          title: "德国——关键角色与SIGEP的机遇",
          desc: [
            "路演期间，活动还聚焦德国市场，德国外出就餐领域的关键参与者也参与其中，包括德国糕点师协会主席、国际面包师和糕点师联合会（UIBC）副主席杰拉尔德·申克，Ballabeni冰淇淋联合创始人焦尔焦·巴拉贝尼，以及Jannys Eis Franchise GmbH前首席执行官马库斯·埃尔伯格。",
          ],
        },
        {
          title: "SIGEP Vision：展望外出就餐行业的未来",
          desc: [
            "SIGEP WORLD 2025的一项核心新内容是SIGEP Vision，这是一个与领先的国际研究机构共同开发的外出就餐行业全球观察站，将全面概述该行业面临的变革、挑战与机遇。",
            "此外，随着两个新展厅的加入，活动总展览面积增加了138,000平方米，产品展示也将得到扩展。SIGEP WORLD 25的亮点包括“明日之味”（Taste of Tomorrow），这是一个基于生物建筑学的全新可持续冰淇淋店概念，以及专注于咖啡和巧克力供应链的“可持续发展区”（Sustainability District），从原产国一直到消费者手中的咖啡和巧克力，还有“从巧克力豆到巧克力棒区域”（Bean-to-Bar Area），将通过一个特定空间来彰显巧克力生产的艺术与科学。",
          ],
        },
        {
          title: "以国际化为核心",
          desc: [
            "SIGEP WORLD正日益全球化。2025年，预计将有来自160个国家的3,000名外国买家通过与意大利对外贸易委员会（ITA）和外交部合作的入境买家计划参会。届时将有1,200个国际品牌参展。参展的不仅有高品质成品，还有尖端技术、创新原料及定制服务。",
          ],
        },
        {
          title: "里米尼：餐饮服务业社区的顶级举办地",
          desc: [
            "46年来，SIGEP World一直在里米尼举办，这里是意大利最著名的旅游目的地之一，凭借丰富的酒店、餐厅及国际交通网络，能够满足游客最多元化的需求。这里是餐饮服务业运营商的“体验式”顶级举办地。",
          ],
        },
      ],
    },
    {
      cover: "6.png",
      title: "2024年ECOMONDO：史上规模最大的一届，数字再创新高",
      desc: [
        "+5% 总参会人数，1620个品牌，166,000平方米展览面积",
        "意大利展览集团第27届国际循环经济及环保展，在里米尼展览中心圆满落幕，各项指标均有所增长",
        "与2023年相比，国际参会人数持续增长，增幅达+4%：德国、西班牙、罗马尼亚、法国、克罗地亚、希腊、塞尔维亚、埃及、土耳其和突尼斯为参会外国代表最多的国家",
        "皮切托·弗拉廷部长：“在Ecomondo，意大利是生态转型的赢家”",
      ],
      body: [
        {
          title: "",
          desc: [
            "里米尼，2024年11月8日——所有调查和预测都表明：可持续性已成为全球社会和企业的当务之急和未来战略的核心。在意大利展览集团举办的国际循环经济及环保展Ecomondo 2024上，这一理念得到了体现，并成就了其有史以来规模最大的一届盛会，该盛会今日于里米尼展览中心圆满落幕。",
            "与上一届相比，总参会人数增长+5%（国际参会人数增长+4%），1620个参展品牌占据了展览中心内166,000平方米的展区，展览中心还新增了两座展厅，以满足企业日益增长的参展需求（参展商数量较2023年增长+10%）。",
            "这是企业技术创新、研究领域、行业专业人士和国际代表团交流的平台，他们参与了200多场会议和研讨会，得益于GreentechInsights b2b平台，这些会议和研讨会也可在线观看。同时，来自世界各地的获准参会记者人数达到650人，使此次展会和里米尼备受国际瞩目。",
          ],
        },
        {
          title: "ECOMONDO，国际可持续性中心",
          desc: [
            "得益于与意大利对外贸易委员会（ITA）和外交部及国际合作部（MAECI）的协同合作，以及意大利展览集团全球区域顾问网络和艾米利亚-罗马涅大区的协作，这些数字证明了Ecomondo作为绿色与循环经济领域国际主要中心的地位。外国参会者人数持续增长，主要来自欧洲-地中海地区，特别是德国、西班牙、罗马尼亚、法国、克罗地亚、希腊、塞尔维亚、埃及、土耳其和突尼斯。来自121个国家的国际参会者、72个全球性行业协会和机构、65个国家的650名买家（尤其是来自北非、欧洲、北美和拉丁美洲的买家），以及亚洲买家数量的显著增加，共同构成了一个关系与交流网络，在展会四天内促成了3500场商务对接活动。",
            "活动结束时收集到的成果证实了Ecomondo多年来所培养的关系网络的价值，它在国际层面真正发挥了绿色经济“社区催化剂”的作用。",
          ],
        },
        {
          title: "皮切托·弗拉廷部长：在Ecomondo，意大利是生态转型的赢家",
          desc: [
            "众多机构代表的出席凸显了Ecomondo的重要性，它不仅是讨论我国主要议程问题的场所，也是与国际情景相关联的平台。",
            "环境与能源安全部长吉尔伯托·皮切托·弗拉廷为活动致开幕词，他提到Ecomondo是“观察国家经济的独特窗口。在这里，意大利是生态转型的赢家，旨在实现脱碳、提高效率、合理利用自然资源和推动创新。”",
          ],
        },
        {
          title: "创新，可持续发展的关键",
          desc: [
            "这是2024年Ecomondo展会的核心主题，这一主题不仅体现在初创企业的现象中，也体现在参展品牌上：在过去五年里，78.4%的企业为可持续性发展投入了创新资金（这是Ecomondo与Unioncamere针对展会生态系统中的企业样本进行的调查）。",
            "在Ecomondo展会上，创新也得到了表彰。洛伦佐·卡尼奥尼绿色创新奖授予了提出前沿解决方案和产品的初创企业。企业和公共行政部门的生态创新也获得了由可持续发展基金会和Ecomondo共同设立的可持续发展奖的肯定。",
          ],
        },
        {
          title: "国际绿色转型的现状",
          desc: [
            "展会四天内安排了200多场活动，全面展示了国际范围内可持续性领域的最新趋势和挑战：从循环经济到生物经济、水经济与蓝色经济、气候变化监测以及可持续金融。这些活动由Ecomondo技术科学委员会协调安排，该委员会主席由博洛尼亚大学的法比奥·法瓦教授担任。",
            "绿色经济大会（今年第13届大会也拉开了Ecomondo展会的序幕）将这些话题置于国家情景数据的框架内：二氧化碳排放量减少了6%以上，意大利成为循环经济领域的领先国家（每消耗1公斤资源可产生3.6欧元的国内生产总值），可再生能源发电量占总发电量的44%以上，且国家有机生产呈增长趋势。此外，还概述了当前面临的挑战，如土壤消耗逐渐增加以及可持续交通的目标。",
          ],
        },
        {
          title: "绿色职业之窗，瓦尔迪塔拉部长的参与",
          desc: [
            "展会高度关注培训与职业资格问题，旨在引导年轻人投身当前及未来的绿色职业。在Ecomondo最后一天上午，教育部长朱塞佩·瓦尔迪塔拉就此话题发表讲话，概述了未来的培训战略。目前，为适应企业投资和项目对绿色专业人才的需求，对技能提升和再培训的需求已显著增加。大学生和专业人士参加了Ecomondo展会，并参与了培训和指导活动，同时还有2800名高中生参与。",
            "本届展会取得了圆满成功，得到了历史悠久且重要的合作伙伴的助力：供应链联盟和商业协会（包括CONAI、Utilitalia和Assoambiente、Confindustria等），以及国家机构（如MASE、MIMIT、ITA）和国际组织（包括欧盟委员会、经合组织、粮农组织、欧洲环境署和国际固体废弃物协会）。",
          ],
        },
        {
          title:
            "Ecomondo将于2025年11月4日至7日在意大利展览集团的里米尼展览中心再次举行。",
        },
      ],
    },
    {
      cover: "7.png",
      title:
        "意大利展览集团（IEG）推出新活动：海事行业可持续创新成为MIT.EQ“海洋创新技术装备博览会”焦点",
      body: [
        {
          title: "",
          desc: [
            "2025年3月5日至7日于里米尼展览中心与KEY——国际能源展同期举办",
            "2024年10月17日，里米尼——意大利展览集团（IEG）推出MIT.EQ“海洋创新技术装备博览会”，这是其绿色与科技系列展会中的一项新活动。MIT.EQ将于2025年3月5日至7日与KEY——国际能源展同期举办。后者是地中海地区专注于能效和可再生能源领域综合技术和解决方案的欧洲领先展会，2024年吸引了来自107个国家的53,157名观众和837家参展商，其中33%来自海外。",
            "作为IEG意大利与欧盟业务发展经理，马尔科·切基尼解释道：“MIT.EQ将是一场面向海事行业专业人士的国际性贸易展会，源自一个明确的市场需求：即关注该行业的可持续发展和创新。海事行业对意大利而言是一个战略行业，我们认为有必要举办一场专注于整个供应链的新垂直活动。MIT.EQ不仅是一场B2B贸易展会，还是一个交流平台，旨在提升行业内可持续创新的讨论，强调企业对于尖端、可持续生态技术创新日益增长的承诺。”",
            "在全球海事行业需求日益增长，同时面临可持续性和安全等挑战之际，促进整个行业生产和服务链内的网络合作倡议具有战略意义。",
            "MIT.EQ将吸引来自多个领域的公司参展：船舶建造中的供应和分包；内饰、家具、系统和材料供应；港口和游艇码头供应；安全和救援设备；机械、推进系统、系统和配件；技术服装和配件；船舶电子设备。",
            "此外，MIT.EQ还将配套一个技术科学计划，主要聚焦海事行业可持续创新这一主题。讨论环节将与商务交流和整个船舶及游艇行业所采纳的最佳解决方案的经验分享交替进行。可持续性将在讨论和展览提案中发挥主要作用，旨在应对国际减碳挑战，使船舶行业在国际海事界成为小众领域的领导者。",
          ],
        },
      ],
    },
    {
      cover: "8.png",
      title: "TTG国际旅游业展览会：盛况空前，参展人数较前届增长9%",
      desc: [
        "第61届国际旅游业展览会所有目标均已实现，外国参会人数更是激增15%",
        "参展品牌达2700家，来自75个国家和地区的1000名海外买家及66个海外目的地参展，匹配平台上共进行了18000场商务洽谈，683名记者参会",
        "2024年的主旋律“真相”（Veritas）在9个场馆的200场活动中由250位演讲者展开，活动包括演讲、研讨会和会议",
      ],
      subTitle:
        "里米尼，2024年10月11日——由意大利展览集团（IEG）主办的第61届TTG国际旅游业展览会与第2届InOut酒店设计、海滩、花园和室外布景、建筑和室内设计展于里米尼展览中心圆满落幕，参展的专业观众人数创下新高（+9%总增长，+15%外国参会人数，与2023年相比）。参会人数的激增再次印证了旅游业的蓬勃活力，使本次展会成为机构、国际买家、业内企业和媒体关注的焦点。此次全国最重要的旅游行业B2B盛会汇聚了2700家参展品牌、来自世界各地的1000名买家、55家初创公司以及683名意大利和外国记者，在为期三天的展会期间，共举行了18000场商务洽谈，同样创下纪录。",
      body: [
        {
          title: "旅游业，对真相的新需求应运而生",
          desc: [
            "TTG展会以“真相”（Veritas）为主题，在9个场馆的200场活动中，通过演讲和辩论，从不同角度对“真相”这一主题进行了深入探讨。在当前这个人为编造故事、虚假新闻泛滥、评论常误导公众的时代，旅游业对真相的追求聚焦于依靠关于目的地的真实故事、真实趋势数据和科学可验证的市场分析。",
          ],
        },
        {
          title:
            "TTG旅游体验展，汇聚全球千名买家及66个海外目的地。意大利20个大区齐亮相",
          desc: [
            "村落、艺术城市、美食美酒，以及骑行旅游的兴起，使意大利成为最受欢迎的目的地，而这些只是吸引来自62个国家（55%来自欧洲，45%来自世界其他地区）的千名外国买家的众多亮点中的一部分，其中北美地区占比最高（18%），其次是英国（15%）和德国（10%）。TTG正逐渐成为越来越国际化的展会，吸引了来自欧洲、亚洲、中东、非洲以及美洲的66个海外目的地参展。TTG还将意大利20个大区带到了里米尼，使本次展会成为入境旅游的卓越交汇点。",
          ],
        },
        {
          title: "意大利豪华旅游蓬勃发展",
          desc: [
            "意大利还是欧洲豪华酒店市场的领头羊，年营业额为305亿欧元，市场份额占21%。在 意大利，45%的酒店投资聚焦于豪华细分市场，且主要集中在罗马、米兰、威尼斯和佛罗伦萨等顶级城市以及沿海和山区（来源：德勤）。TTG紧跟这一趋势，创办了专属活动：TTG奢华盛典。该活动形式有1400场买家参与的预约洽谈，充分展示了意大利豪华旅游从业者的专业素养，专注于满足寻求非凡体验的新高端游客的需求。",
          ],
        },
        {
          title: "TTG NEXT初创企业",
          desc: [
            "TTG 2024打造了一个真正的旅游创新区，55家初创企业蓄势待发，准备在科技、移动性、好客之道、体验和旅游经营等领域颠覆整个行业。该计划包括20场会议和圆桌讨论，以及初创企业推介环节，评选出旅游业最佳新兴初创企业。",
          ],
        },
        {
          title: "桑坦切：“旅游业是我们国家经济和文化发展的重要支柱”",
          desc: [
            "代表旅游业和酒店业各个部门的行业协会，以及大学和文化机构等学术界代表均出席了TTG和InOut。在开幕式上，旅游部长达妮埃拉·桑坦切强调了旅游业作为国家发展重要支柱的不可或缺的作用，并指出TTG是意大利在全球推广旅游业的重要活动。与会嘉宾还有意大利展览集团总裁毛里齐奥·埃尔梅蒂、里米尼市长贾米尔·萨德霍尔瓦德、艾米利亚-罗马涅大区旅游议员安德烈亚·科尔西尼、意大利旅行社协会主席皮尔·埃扎亚、意大利有组织旅游联合会主席弗兰科·加蒂诺尼、意大利航空公司代表协会主席弗拉维奥·吉林赫利、意大利酒店业协会主席贝尔纳博·波卡、圣马力诺共和国旅游国务秘书费德里科·佩迪尼·阿马蒂以及参议院副议长詹马尔科·森蒂尼奥。",
          ],
        },
        {
          title: "InOut：专注于酒店市场的第二届展会",
          desc: [
            "专注于酒店及非酒店接待业的室内和室外家具展——第二届InOut展会再次取得成功。展示了从酒店客房设计到绿化规划，从材料和表面选择到海滩度假村设计等行业的新趋势、尖端技术和新解决方案。",
            "意大利展览集团期待在2025年10月8日至10日于里米尼展览中心举办的下一届展会上，再次迎来意大利和国际旅游业的全体从业者。",
          ],
        },
      ],
    },
    {
      cover: "9.png",
      title:
        "意大利展览集团（IEG）与意大利陶瓷机械及设备制造商协会（Acimac）宣布Tecna展会圆满落幕",
      body: [
        {
          title: "",
          desc: [
            "2024年10月1日，里米尼——由意大利展览集团（IEG）与意大利陶瓷机械及设备制造商协会（Acimac）联合主办、于里米尼展览中心举办的第28届Tecna国际表面技术与陶瓷技术览会圆满落幕，取得巨大成功。Tecna展会是表面处理技术及陶瓷技术领域的国际盛会，吸引了来自100多个国家的参观者，再次确立了其作为展示节能技术、主题展览和与顶级买家进行商务洽谈的世界领先活动的地位。同时，Decortech会议也引发了广泛关注。",
            "Tecna展会再次证明了其作为行业内最具创新性的国际贸易展览会的地位，参观人数与上届展会持平。350家参展品牌对此次展会表示满意，他们接待了来自西班牙、土耳其、印度、巴西、阿尔及利亚、埃及、墨西哥、波兰、伊朗、印度尼西亚、阿根廷、俄罗斯、摩洛哥和越南等100多个国家的优质运营商和买家，共同探讨将最新创新技术和意大利顶级制造技术融入未来项目的可行性。非洲大陆的代表也阵容强大，参展商来自加纳、乌干达、埃塞俄比亚、尼日利亚、南非、坦桑尼亚、肯尼亚、莫桑比克、苏丹、卢旺达、安哥拉和喀麦隆等国家。",
            "参展公司的研发部门聚焦于人工智能、数字化、节能和可持续性发展等关键领域，为行业的未来发展提出了前瞻性的解决方案。以替代能源为动力的窑炉、更先进且排放优化的自动化生产线以及数字装饰应用等解决方案都吸引了参观者的关注。",
            "意大利对外贸易委员会（ITA）合作组织的1600场商务洽谈也彰显了国际参与者对展出技术的高度关注，这些洽谈旨在探索最佳投资机会。这些会议涉及来自36个国家的150位顶级受邀买家。",
            "为期四天的展会还安排了丰富多彩的活动，包括开幕式会议和TecnAwards颁奖典礼。开幕式会议由专家Paolo Magri和Marco Taisch分析了影响意大利该行业公司出口的地缘政治动态，并介绍了潜在的国际形势，为参会者提供了宝贵的见解。第二届TecnAwards颁奖典礼则邀请了全球陶瓷行业的领军企业齐聚里米尼，领取由意大利机械制造商颁发的奖项，以表彰过去两年内国内外客户进行的最佳投资。",
            "现已举办第二届的Decortech会议邀请了能够为表面打造极致美学品质的技术领先生产商，这种增值效应提升了成品的竞争力。在Decortech展区，赞助公司全面展示了建筑和设计领域表面装饰和精加工的最新创新成果，引起了参会者的高度关注。",
            "此外，创新展区还举办了多场专注于原材料管理、服务化转型、网络安全和工业5.0等战略议题的技术会议。创新区还成为初创企业的聚集地，并向三个最具创新性的商业创意颁发了洛伦佐·卡尼奥尼奖。",
            "Tecna还通过与艾米利亚-罗马涅地区的多所技术学院开展教育合作，关注行业的发展前景。这些学院的学生受邀于周五专程来到展览中心，参加为期一天的活动，探索这个蓬勃发展的意大利行业所提供的机遇。活动吸引了350多名学生参加。",
            "下一届Tecna展会将于2026年9月举办。",
          ],
        },
      ],
    },
    {
      cover: "1.png",
      title:
        "意大利展览集团亚洲公司荣获新加坡最佳意大利中小企业奖：意大利商会新加坡分会（ICCS）颁发奖项",
      desc: [
        "新加坡意大利商会表彰意大利展览集团（IEG）东南亚子公司为促进意大利与新加坡经济合作所做的贡献",
        "并另行宣布，根据Stax排名，意大利展览集团（IEG）连续第二年跻身全球前27大展会主办方中的唯一意大利企业",
      ],
      subTitle:
        "2024年9月25日，新加坡——在2024年新加坡意大利商会（ICCS）商业奖颁奖典礼上，意大利展览集团亚洲私人有限公司（IEG Asia Pte. Ltd.）荣获“新加坡最佳意大利中小企业奖”。该奖项是新加坡意大利商会举办的一项备受瞩目的活动，旨在表彰在促进意大利与狮城之间国际关系和经济合作方面发挥重要作用的企业。",
      body: [
        {
          title: "获奖原因",
          desc: [
            "评审团之所以将奖项授予IEG亚洲公司，是因为该公司在新加坡的MICE（会议、奖励旅游、大会/展览）领域，特别是通过Sigep Asia和SIJE两大展会进行了大量投资，并致力于塑造狮城的商业格局，推动与意大利的合作。颁奖典礼上，意大利驻新加坡大使丹特·布兰迪、意大利对外贸易委员会（ITA）新加坡办事处主任焦尔焦·卡尔维里以及意大利和新加坡商界领袖和代表出席，意大利展览集团亚洲公司首席执行官伊拉里亚·切塞罗和国际业务发展总监弗朗切斯科·桑塔领奖，意大利展览集团首席运营官卡洛·科斯塔也一同出席。",
          ],
        },
        {
          title: "致辞与数据",
          desc: [
            "“我要感谢新加坡意大利商会以及所有支持IEG Aisa在新加坡发展的合作伙伴。新加坡是意大利企业越来越多选择的城市，也是整个东盟地区最重要的经济中心。”伊拉里亚·切塞罗表示，“在短短一年多时间里，IEG Aisa已经从当地和国际主办方手中收购了食品和饮料、酒店餐饮和珠宝行业的展会项目，同时也得到了新加坡Algebra Pte. Ltd.公司的支持和咨询。我们克服了重重挑战，未来还会有更多挑战等待我们：我们将以坚韧不拔的精神和专业的态度，继续拓展在新加坡和东盟国家的业务。”",
            "“这份荣誉是对我们的肯定，证明了我们致力于将意大利的卓越产品推向世界，特别是新加坡。新加坡是意大利对东南亚地区出口的主要目的地，2023年出口额超过28亿欧元（+16.9%）。”弗朗切斯科·桑塔补充道，“这是对我们团队工作和热情以及合作伙伴和客户信任的肯定。”",
            "“根据新加坡贸工部估算，2024年第一季度新加坡经济增长2.7%，高于2023年第四季度。考虑到这些数据，这份认可尤为重要。”卡洛·科斯塔强调，“它证明了我们秉持的‘IEG精神’是成功的。这种精神鼓励我们以好奇心、专业性、团队协作和注重细节的态度面对市场，即使远离总部，我们也始终与集团所有办事处保持密切联系。”",
          ],
        },
        {
          title: "超越新加坡：IEG是Stax全球排名中唯一的意大利企业",
          desc: [
            "与此同时，还有另一项重要认可：根据全球战略咨询公司Stax排名，意大利展览集团连续两年跻身全球前27大展会主办方中的唯一意大利企业，这些主办方通过自身直接举办的展会创造了可观的收入。另一个显著事实是，在排名前27位的企业中，有4家是IEG的工业或商业合作伙伴，这些合作伙伴将IEG的优势产业推向了中东和美洲等战略地理区域。事实上，Stax排名第一的Informa是IEG的合作伙伴，双方共同举办了专注于黄金和珠宝供应链的JGTD展会。Koelnmesse（科隆国际展览有限公司）也是IEG的合作伙伴，双方共同举办了Sigep China展会。此外，Deutsche Messe是IEG在墨西哥和加拿大的合作伙伴。而Nürnberg Messe（纽伦堡展览公司）也榜上有名，该公司与IEG在维琴察展览中心共同举办了Focus on PCB展会。",
          ],
        },
      ],
    },
    {
      title:
        "意大利自动售货服务协会（CONFIDA）与意大利展览集团（IEG）携手合作，共同开发VENDITALIA展会",
      body: [
        {
          title: "",
          desc: [
            "意大利及国际领先的自动售货行业贸易展每两年举办一次，如今已举办至第13届。2024年，该展会吸引了22,300名专业观众，比2022年增长了10%。",
            "2024年9月2日，里米尼——意大利自动售货服务协会（CONFIDA）选择意大利展览集团（IEG）作为战略合作伙伴，共同打造欧洲自动售货行业的主要展会“VENDITALIA”。为此，双方成立了一家新公司，负责未来在里米尼举办的VENDITALIA展会，并监督其产品发展和国际化进程。",
            "因此，自2026年春季起，VENDITALIA将在里米尼展览中心的场馆内举办。",
            "意大利展览集团与由总裁埃尔内斯托·皮洛尼亲自代表的VENDITALIA签署了协议，该协议得到了展览中心董事会和CONFIDA股东的一致批准，标志着双方将于秋季成立一家新公司（NewCo）的进程开始。新公司中，IEG持股51%，Confida持股49%，自2026年起，新公司将负责每两年举办一次“VENDITALIA - 自动售货博览会”，这是国际领先的自动售货服务展会，专注于食品、饮料以及越来越多产品的自动分销领域。",
            "“Venditalia”成立于1998年热那亚，持续不断发展，吸引了越来越多的行业专业人士。2004年，它获得了“国际”活动的地位，今年迎来了第13届展会，取得了显著成果：32,000平方米的展览面积、300家参展商（其中40%为国际参展商，主要来自法国、西班牙、德国、波兰、葡萄牙和英国）以及22,300名专业观众。除了提供种类繁多的自动售货机、产品和服务外，“Venditalia”还安排了一系列会议和研讨会，深入探讨该行业的主要挑战和机遇。",
            "这些成果和知名度也得益于意大利在欧洲自动售货行业的领先地位：2023年，该行业总营业额达到20亿欧元（自动售货和办公咖啡服务），提供了50亿次的消费服务，创造了33,000多个就业岗位，涵盖3,000多家公司，并安装了830,000多台自动售货机（欧洲最大的机队）。此外，还有其他参与供应链的公司，如食品生产商和配件及技术制造商。在后者方面，意大利占据主导地位：生产的70%机器用于出口，许多自动售货机配件也出口国外。",
            "IEG总裁毛里齐奥·埃尔梅蒂评论道：“我们对这一协议感到非常满意。这将使我们在里米尼举办一场新的国际活动，这是欧洲该行业最重要的活动。我们很自豪，CONFIDA作为与Confcommercio世界相关联的协会和行业领导者，认可我们作为贸易展组织者的专业知识，并选择我们作为战略合作伙伴，以继续发展这一已成为行业领导者的活动。事实上，IEG的决定旨在从国家和国际层面发展‘Venditalia - 自动售货博览会’。为此，IEG的一个工作组将支持该项目，以便通过发展产品和国际化两个方面，在2026年版中引入一系列进化元素和新内容。”",
            "“经过几年的成功和发展，”Venditalia总裁埃尔内斯托·皮洛尼评论道，“我们停下来思考，并带着继续推动Venditalia增长的坚定意图，我们寻找了一个新的合作伙伴，而IEG因其提供的战略前景而成为最佳选择。我们之间创造的协同效应对于继续巩固Venditalia在我们已经占据主导地位的意大利和欧洲等市场的重要性至关重要，但对于在未来首次进入新的国际市场也尤为重要，从而进一步提高意大利和欧洲自动售货行业所提供的令人难以置信的技术和产品创新的知名度。”",
          ],
        },
      ],
    },
    {
      cover: "1.png",
      title: "美国FB国际公司由意大利展览集团（IEG）100%控股持有",
      desc: [
        "托马斯·坎切拉拉（Tommaso Cancellara），总裁兼首席执行官",
        "意大利展览集团（IEG Group）首席创意官（CCO）卡洛·科斯塔（Carlo Costa）已被提名为董事会主席",
        "这家搭建公司在2023年承接了130场活动，营业额达到2700万美元",
      ],
      body: [
        {
          title: "",
          desc: [
            "2024年8月30日，里米尼——FB国际完全由意大利展览集团（Italian Exhibition Group）控股，该集团通过IEG美国公司行使了购买选择权，现在拥有其全部所有权。",
            "这家美国公司的管理层也焕然一新，托马斯·坎切拉拉担任总裁兼首席执行官。另一方面，董事会由主席卡洛·科斯塔主持，他同时也是整个IEG集团的首席创意官。",
            "自1988年以来，FB国际公司（FB International Inc.）一直在为美国各地的展览和活动设计和搭建展台，并于2018年加入IEG集团。",
            "托马斯·坎切拉拉表示：“2023年，FB国际参与了130场活动，营业额达到2700万美元，担任总承包商或指定承包商。”",
            "FB国际拥有100多名员工，以及一个跟随项目、使用多种语言的“多元文化团队”，以创建展览项目。",
            "公司总部设在新泽西州，运营中心位于拉斯维加斯，以便及时覆盖美国主要的贸易展会场所：纽约和拉斯维加斯。",
            "卡洛·科斯塔解释说：“其办公室的位置使FB国际能够陪伴来自世界各地的400名客户，在美国各地的贸易展会和活动上亮相。",
            "2023年11月，FB迁往位于新泽西州费尔菲尔德的新总部，在一个现代化且组织有序的11000平方米设施中，木工、油漆和图形车间与设计好的展装配件的生产和组装共同运作。服务于整个西海岸的位于内华达州拉斯维加斯的总部也已迁至一个新的3500平方米的现代化设施。",
          ],
        },
      ],
    },
  ],
  exhibitions: [
    {
      time: "2024年7月29-30日",
      location: "纽约，美国",
      logo: "CHILDREN'S.png",
      name: "美国婴童时尚产业博览会",
      desc: "The Elevated International Baby and Children\n\nFashion Industry Expo in New York City",
      url: "https://www.childrenshow.com/",
      group: "生活方式与娱乐",
    },
    {
      time: "2024年9月6-8日",
      location: "意大利",
      logo: "VoClock800-7c4edb9f.png",
      name: "VO'CLOCK PRIVÉ 国际手表展",

      url: "https://www.vicenzaoro.com/en/vo-clock",
      group: "珠宝与时尚",
    },
    {
      time: "2024年9月6-10日",
      location: "意大利",
      logo: "VICENZAORO.png",
      name: "VICENZAORO SEPTEMBER 国际珠宝展",
      desc: "International Jewellery Show",
      url: "https://www.vicenzaoro.com/en",
      group: "珠宝与时尚",
    },
    {
      time: "2024年9月6-10日",
      location: "意大利",
      logo: "LOGO_PALAKISS.png",
      name: "PALAKISS SUMMER\n国际珠宝交付展夏季展",

      url: "https://www.palakiss.com/en/",
      group: "",
    },
    {
      time: "2024年9月11-14日",
      location: "巴西",
      logo: "LOGO_fesqua.png",
      name: "FESQUA 巴西门窗和框架展",
      desc: "The Biggest and Most Important Event for the Frame Market in Latin America",
      url: "https://fesqua.com.br/",
      group: "绿色与科技",
    },
    {
      time: "2024年9月11-14日",
      location: "巴西",
      logo: "LOGO_EBRATS.png",
      name: "EBRATS\n巴西表面处理展",
      desc: "Brazilian Surface Treatment Encounter and Exposition",
      url: "https://www.ebrats.com.br/",
      group: "绿色与科技",
    },
    {
      time: "2024年9月19-22日~2024年9月26-29日",
      location: "意大利",
      logo: "LOGO_abilmente-01.png",
      name: "ABILMENTE\n国际工艺品博览会",
      desc: "The Creative Ideas Show",
      url: "https://www.abilmente.org/en/turin",
      group: "生活方式与娱乐",
    },
    {
      time: "2024年9月24-27日",
      location: "意大利",
      logo: "LOGO_tecna.png",
      name: "TECNA\n国际陶瓷技术展",
      desc: "International Exhibition of Technologies and Supplies for Surfaces",
      url: "https://en.tecnaexpo.com/",
      group: "绿色与科技",
    },
    {
      time: "2024年10月9-11日",
      location: "意大利",
      logo: "LOGO_TTG.png",
      name: "TTG\n国际旅游业展览会",
      desc: "B2B show for the promotion of global tourism",
      url: "https://en.ttgexpo.it/",
      group: "旅游和酒店",
    },
    {
      time: "2024年10月9-11日",
      location: "墨西哥",
      logo: "LOGO_ITM.png",
      name: "ITM Industrial Tranformation Mexico\n墨西哥智能制造工业展",
      desc: "Platform for digital transformation and Intelligent Manufacturing in Mexico",
      url: "https://industrialtransformation.mx/",
      group: "绿色与科技",
    },
    {
      time: "2024年10月9-11日",
      location: "意大利",
      logo: "LOGO_inout.png",
      name: "InOut 酒店设计、海滩、花园和室外布景、建筑和室内设计展",
      desc: "The Contract Community",
      url: "https://en.inoutexpo.it/",
      group: "旅游和酒店",
    },
    {
      time: "2024年10月25-27日",
      location: "迪拜",
      logo: "LOGO_DMS.png",
      name: "DUBAI MUSCLE SHOW\n迪拜国际健身展",
      desc: "The Middle East’s Leading Fitness & Bodybuilding Show",
      url: "https://www.dubaimuscleshow.com/",
      group: "健身与运动",
    },
    {
      time: "2024年10月25-27日",
      location: "迪拜",
      logo: "LOGO_DA.png",
      name: "Dubai Active Industry\n迪拜运动展",
      desc: "The Middle East’s Leading International Fitness & Wellness Trade Show",
      url: "https://dubaiactiveshow.com/",
      group: "健身与运动",
    },
    // {
    //   time: "2024年10月26-27日",
    //   location: "意大利",
    //   logo: "LOGO-ESOTIKA.png",
    //   name: "国际宠物展",

    //   url: "https://www.esotikapetshow.it/fiera/rovigo-ottobre-2023/",
    //   group: "生活方式与娱乐",
    // },
    {
      time: "2024年11月5-8日",
      location: "意大利",
      logo: "ecomondo-logo-2023-5.jpg",
      name: "ECOMONDO\n国际绿色能源及环保展",
      desc: "International Trade Fair of Material & Energy Recovery and Sustainable Development",
      url: "https://en.ecomondo.com/",
      group: "绿色与科技",
    },
    {
      time: "2024年11月6-8日",
      location: "意大利",
      logo: "6_LOGO_A&T.png",
      name: "Automation & Testing Nord Est\n智能制造、智慧物流、检测和流程控制展",
      desc: "The Trade Show dedicated to Innovation, Technologies, Reliability and Skills 4.0 – 5.0",
      url: "https://vicenza.aetevent.com/default.aspx?site=ENG",
      group: "绿色与科技",
    },
    {
      time: "2024年11月12-14日",
      location: "迪拜",
      logo: "LOGO_JGTD.png",
      name: "JGT DUBAI 迪拜珠宝、宝石和技术展",
      desc: "Jewellery, Gem & Technology Dubai",
      url: "https://www.jgtdubaijewelleryshow.com/",
      group: "珠宝与时尚",
    },
    {
      time: "2024年11月19-21日",
      location: "意大利",
      logo: "LOGO_IBE.jpeg",
      name: "IBE\n巴士客车展",
      desc: "INTERMOBILITY AND BUS EXPO",
      url: "https://en.expoibe.com/",
      group: "绿色与科技",
    },
    {
      time: "2024年11月22-24日",
      location: "巴西",
      logo: "btff.png",
      name: "BTFF\n巴西健身展",
      desc: "BRASIL TRADING FITNESS FAIR",
      url: "https://btff.com.br/",
      group: "健身与运动",
    },
    // {
    //   time: "2024年",
    //   location: "意大利",
    //   logo: "LOGO_cosmofood.png",
    //   name: "国际食品饮料展",

    //   url: "https://en.cosmofood.it/",
    //   group: "食品与饮料",
    // },
    {
      time: "2025年1月17-21日",
      location: "意大利 维琴察展览中心",
      logo: "VICENZAORO.png",
      name: "VICENZAORO January\n国际珠宝展",
      desc: "International Jewellery Show",
      url: "https://www.vicenzaoro.com/en",
      group: "珠宝与时尚",
    },
    {
      time: "2025年1月17-21日",
      location: "意大利 维琴察展览中心",
      logo: "1_LOGO_TGOLD.webp",
      name: "T-GOLD\n国际珠宝设备和加工技术展",
      desc: "International Jewellery Technology Show",
      url: "https://www.vicenzaoro.com/en/t-gold",
      group: "珠宝与时尚",
    },
    {
      time: "2025年1月17-21日",
      location: "意大利 维琴察展览中心",
      logo: "LOGO_PALAKISS.png",
      name: "PALAKISS WINTER\n国际珠宝贸易及交付展",
      desc: "Trade & Delivery Jewellery Show",
      url: "https://www.vicenzaoro.com/en/t-gold",
      group: "珠宝与时尚",
    },
    {
      time: "2025年1月17-21日",
      location: "意大利 维琴察展览中心",
      logo: "VO_Vantage.png",
      name: "VO VINTAGE\n国际珠宝和古董手表展",
      desc: "Watches and Jewellery",
      url: "https://www.vicenzaoro.com/en/vicenzaoro-vintage",
      group: "珠宝与时尚",
    },
    {
      time: "2025年1月18-22日",
      location: "意大利 里米尼会展中心",
      logo: "2_LOGO_SIGEP25.png",
      name: "SIGEP World\n国际手工冰淇淋、烘焙和咖啡展",
      desc: "International Trade Show of Artisan Gelato, Pastry, Bakery and the Coffee World",
      url: "https://en.sigep.it/",
      group: "食品与饮料",
    },
    {
      time: "2025年2月7-9日",
      location: "意大利 里米尼会展中心",
      logo: "LOGO_pescareshow.png",
      name: "PESCARE\n国际钓鱼和划船运动展",
      desc: "THE INTERNATIONAL EVENT FOR FISHING AND BOATING SPORTS",
      url: "https://www.pescareshow.it/en/",
      group: "健身与运动",
    },
    {
      time: "2025年2月7-8日",
      location: "沙特阿拉伯 利雅得",
      logo: "Logo-Black-Green.png",
      name: "Riyadh Muscle Show\n沙特国际健身展",
      desc: "Saudi Arabia’s Leading International Fitness & Bodybuilding Event",
      url: "https://riyadhmuscle.com/",
      group: "健身与运动",
    },
    {
      time: "2025年2月9-10日",
      location: "纽约，美国",
      logo: "CHILDREN'S.png",
      name: "Children’s Show\n美国婴童时尚产业博览会",
      desc: "The elevated International Baby and Children Fashion Industry Expo",
      url: "https://www.childrenshow.com/",
      group: "生活方式与娱乐",
    },
    {
      time: "2025年2月12-14日",
      location: "意大利 灵格托展览中心",
      logo: "6_LOGO_A&T.png",
      name: "Automation & Testing\n智能制造、智慧物流、检测和流程控制展",
      desc: "The Trade Show dedicated to Innovation, Technologies, Reliability and Skills 4.0 – 5.0",
      url: "https://vicenza.aetevent.com/default.aspx?site=ENG",
      group: "绿色与科技",
    },
    {
      time: "2025年2月16-18日",
      location: "意大利 里米尼会展中心",
      logo: "LOGO_B&F.png",
      name: "BEER & FOOD ATTRACTION\n户外啤酒、饮料和食品展",
      desc: "The Eating Out Experience Show",
      url: "https://en.beerandfoodattraction.it/",
      group: "食品与饮料",
    },
    {
      time: "2025年2月16-18日",
      location: "意大利 里米尼会展中心",
      logo: "LOGO_BBT.png",
      name: "BBTECH EXPO \n国际啤酒和饮料技术专业贸易展览会",
      desc: "The professional trade fair for beer and beverage technologies",
      url: "https://en.bbtechexpo.com/",
      group: "食品与饮料",
    },

    {
      time: "2025年2月17-19日",
      location: "意大利 里米尼会展中心",
      logo: "LOGO_enada_spring.jpg",
      name: "ENADA PRIMAVERA\n国际娱乐与游戏展",
      desc: "International Amusement & Gaming Show",
      url: "https://en.enada.it/",
      group: "生活方式与娱乐",
    },

    {
      time: "2025年2月17-19日",
      location: "意大利 里米尼会展中心",
      logo: "10_LOGO_riminiamusm.jpg",
      name: "Rimini Amusement Show\n里米尼游乐体验展",
      desc: "",
      url: "https://www.riminiamusement.it/",
      group: "生活方式与娱乐",
    },
    {
      time: "2025年2月19-21日",
      location: "意大利 米兰国际展览中心",
      logo: "11_LOGO_MYPLANT.png",
      name: "MYPLANT & GARDEN\n国际植物与园艺展",
      desc: "International Green Expo",
      url: "https://myplantgarden.com/en/",
      group: "绿色与科技",
    },
    {
      time: "2025年2月20-23日",
      location: "意大利 维琴察展览中心",
      logo: "LOGO_abilmente-01.png",
      name: "ABILMENTE\n国际手工艺品博览会-维琴察站",
      desc: "The Creative Ideas Show",
      url: "https://www.abilmente.org/en/vicenza",
      group: "生活方式与娱乐",
    },
    {
      time: "2025年2月27日-3月2日",
      location: "意大利 罗马展览中心",
      logo: "LOGO_abilmente-01.png",
      name: "ABILMENTE\n国际手工艺品博览会-罗马站",
      desc: "The Creative Ideas Show",
      url: "https://www.abilmente.org/en/roma",
      group: "生活方式与娱乐",
    },
    {
      time: "2025年3月27日-30日",
      location: "意大利 米兰Superstudio Maxi",
      logo: "LOGO_abilmente-01.png",
      name: "ABILMENTE\n国际手工艺品博览会-米兰站",
      desc: "The Creative Ideas Show",
      url: "",
      group: "生活方式与娱乐",
    },
    {
      time: "2025年3月5-7日",
      location: "意大利 里米尼会展中心",
      logo: "LOGO_K.EY.png",
      name: "K.EY\n国际能源展",
      desc: "The Energy Transition Expo",
      url: "https://en.key-expo.com/",
      group: "绿色与科技",
    },
    {
      time: "2025年3月5-7日",
      location: "意大利 里米尼会展中心",
      logo: "16_LOGO_MITEQ.png",
      name: "MIT.EQ\n海洋创新技术装备博览会",
      desc: "Marine Innovation Technology Equipment Expo",
      url: "",
      group: "绿色与科技",
    },
    {
      time: "2025年3月5-7日",
      location: "墨西哥 瓜达拉哈拉",
      logo: "17_LOGO_ECOMONDO MEXICO.png",
      name: "ECOMONDO MEXICO\n墨西哥国际绿色能源及环保展",
      desc: "The environmental technology event designed for the Latin American market",
      url: "https://hfmexico.mx/ecomondo/en/",
      group: "绿色与科技",
    },
    {
      time: "2025年3月5-7日",
      location: "墨西哥 瓜达拉哈拉",
      logo: "18_LOGO_RE+.png",
      name: "墨西哥太阳能和储能展",
      desc: "The leading fair for solar energy and storage in Mexico and Latin America",
      url: "https://www.hfmexico.mx/solarpowermexico/",
      group: "绿色与科技",
    },
    {
      time: "2025年3月23-25日",
      location: "意大利 里米尼会展中心",
      logo: "LOGO_MIR.jpg",
      name: "MIR国际现场娱乐、音响和舞台照明博览会",
      desc: "Live Entertainment Expo",
      url: "https://en.mirtechexpo.com/",
      group: "生活方式与娱乐",
    },
    {
      time: "2025年4月1-3日",
      location: "中国 成都",
      logo: "LOGO_ecomondo-china.png",
      name: "Ecomondo China - CDEPE\n成都国际环保博览会暨中欧绿色低碳博览会",
      desc: "Leading the Ecological and Energy Transition in Western China",
      url: "https://www.cdepe.com/",
      group: "绿色与科技",
    },
    {
      time: "2025年4月24-26日",
      location: "中国 深圳",
      logo: "21_LOGO_sigep-china.jpeg",
      name: "SIGEP China\n中国手工冰淇淋、糕点、烘焙和咖啡展",
      desc: "Southern China’s International Expo dedicated to the production of Gelato, Bakery & Coffee",
      url: "https://www.sigepcn.com/cn/",
      group: "食品与饮料",
    },
    {
      time: "2025年5月3-6日",
      location: "意大利 阿雷佐展览会议中心",
      logo: "LOGO_oroarezzo-01.png",
      name: "OROAREZZO\n国际珠宝展",
      desc: "International Jewelry Exhibition",
      url: "https://www.oroarezzo.it/en/",
      group: "珠宝与时尚",
    },
    {
      time: "2025年5月15-17日",
      location: "意大利 里米尼会展中心",
      logo: "23_LOGO_Expodental.png",
      name: "Expodental Meeting\n国际牙科展",
      url: "https://www.expodental.it/en/",
      group: "绿色与科技",
    },
    {
      time: "2025年5月29日-6月1日",
      location: "意大利 里米尼会展中心",
      logo: "RIMINIWELLNESS 23_logo.png",
      name: "RIMINI WELLNESS\n里米尼国际健身展",
      desc: "The Wellness Experience Show",
      url: "https://en.riminiwellness.com/",
      group: "健身与运动",
    },

    {
      time: "2025年6月3-5日",
      location: "巴西 圣保罗",
      logo: "droneshow-robotics-retina-400x85.png",
      name: "DRONE SHOW\n巴西国际无人机展",
      desc: "Leading Event on Drones and connected technologies",
      url: "https://droneshowla.com/en/",
      group: "绿色与科技",
    },
    {
      time: "2025年6月3-5日",
      location: "巴西 圣保罗",
      logo: "img-retina.png",
      name: "MUNDO GEO CONNECT\n巴西国际地质技术和地质信息展",
      desc: "Leading Event on geotechnology and geographical information technologies",
      url: "https://mundogeoconnect.com/2024/en/",
      group: "绿色与科技",
    },
    {
      time: "2025年6月3-5日",
      location: "巴西 圣保罗",
      logo: "img-logo-expo-evtol-color.png",
      name: "Expo eVTOL \n空中交通和创新飞机展",
      desc: "Advanced Air Mobility and Innovative Aircraft",
      url: "https://expoevtol.com/en/",
      group: "绿色与科技",
    },
    {
      time: "2025年6月3-5日",
      location: "巴西 圣保罗",
      logo: "25_LOGO_SpaceBR Show.png",
      name: "SPACEBR SHOW\n巴西空间观测展",
      desc: "Leading Event on Space sector technologies",
      url: "https://spacebrshow.com/en/",
      group: "绿色与科技",
    },
    {
      time: "2025年6月3-4日",
      location: "巴西 圣保罗",
      logo: "28_LOGO_Smart City Business Brazil.png",
      name: "Smart City Business Brazil\n巴西智慧城市展",
      desc: "The main technology and innovation fair for Smart Cities",
      url: "https://www.scbamerica.com/en",
      group: "绿色与科技",
    },
    {
      time: "2025年6月9-11日",
      location: "巴西 圣保罗",
      logo: "29_LOGO_InfraFM.png",
      name: "Infra Fm\n巴西城市设施、物业、职场管理展",
      desc: "The largest and most comprehensive trade show for Facility, Property, and Workplace Management in Latin America.",
      url: "",
      group: "绿色与科技",
    },
    {
      time: "2025年7月10-13日",
      location: "新加坡",
      logo: "LOGO_SIJE.png",
      name: "SIJE - SINGAPORE INTERNATIONAL JEWELRY EXPO\n新加坡国际珠宝展",
      desc: "",
      url: "",
      group: "珠宝与时尚",
    },
    {
      time: "2025年7月16-18日",
      location: "新加坡",
      logo: "LOGO_SIGEP-Asia.png",
      name: "SIGEP Asia\n亚洲手工冰淇淋、烘焙和咖啡展",
      desc: "International Exhibition of Artisan Gelato, Pastry, Bakery, Coffee and Tea",
      url: "https://sigepasia.com.sg/",
      group: "食品与饮料",
    },
    {
      time: "2025年9月5-9日",
      location: "意大利 维琴察展览中心",
      logo: "VICENZAORO.png",
      name: "VICENZAORO珠宝展",
      desc: "International Jewellery Show",
      url: "https://www.vicenzaoro.com/en",
      group: "珠宝与时尚",
    },

    {
      time: "2025年9月25日-28日",
      location: "意大利 罗马展览中心",
      logo: "LOGO_abilmente-01.png",
      name: "ABILMENTE\n国际手工艺品博览会-罗马站",
      desc: "The Creative Ideas Show",
      url: "https://www.abilmente.org/en/roma",
      group: "生活方式与娱乐",
    },
    {
      time: "2025年10月16-19日",
      location: "意大利 维琴察展览中心",
      logo: "LOGO_abilmente-01.png",
      name: "ABILMENTE\n国际手工艺品博览会-维琴察站",
      desc: "The Creative Ideas Show",
      url: "https://www.abilmente.org/en/vicenza",
      group: "生活方式与娱乐",
    },
    {
      time: "2025年10月23日-26日",
      location: "意大利 米兰Superstudio Maxi",
      logo: "LOGO_abilmente-01.png",
      name: "ABILMENTE\n国际手工艺品博览会-米兰站",
      desc: "The Creative Ideas Show",
      url: "",
      group: "生活方式与娱乐",
    },
    {
      time: "2025年10月8-10日",
      location: "意大利 里米尼会展中心",
      logo: "LOGO_inout.png",
      name: "InOut\n酒店设计、海滩、花园和室外布景、建筑和室内设计展",
      desc: "The Contract Community",
      url: "https://en.inoutexpo.it/",
      group: "旅游和酒店",
    },
    {
      time: "2025年10月8-10日",
      location: "意大利 里米尼会展中心",
      logo: "LOGO_TTG.png",
      name: "TTG Travel Experience\n国际旅游业展览会",
      desc: "B2B show for the promotion of global tourism",
      url: "https://en.ttgexpo.it/",
      group: "旅游和酒店",
    },
    {
      time: "2025年10月24-26日",
      location: "迪拜",
      logo: "LOGO_DMS.png",
      name: "DUBAI MUSCLE SHOW\n迪拜国际健身展",
      desc: "The Middle East’s Leading Fitness & Bodybuilding Show",
      url: "https://www.dubaimuscleshow.com/",
      group: "健身与运动",
    },
    {
      time: "2025年10月24-26日",
      location: "迪拜",
      logo: "LOGO_DA.png",
      name: "Dubai Active Industry\n迪拜运动展",
      desc: "The Middle East’s Leading International Fitness & Wellness Trade Show",
      url: "https://dubaiactiveshow.com/",
      group: "健身与运动",
    },
    {
      time: "2025年10月28-30日",
      location: "意大利 维琴察展览中心",
      logo: "6_LOGO_A&T.png",
      name: "Automation & Testing Nord Est\n智能制造、智慧物流、检测和流程控制展",
      desc: "The Trade Show dedicated to Innovation, Technologies, Reliability and Skills 4.0 – 5.0",
      url: "https://vicenza.aetevent.com/default.aspx?site=ENG",
      group: "绿色与科技",
    },
    {
      time: "2025年11月4-7日",
      location: "意大利 里米尼会展中心",
      logo: "41_LOGO_ECOMONDO.png",
      name: "ECOMONDO\n国际循环经济及环保展",
      desc: "International Trade Fair of Material & Energy Recovery and Sustainable Development",
      url: "",
      group: "绿色与科技",
    },
    {
      time: "2025年11月11-13日",
      location: "迪拜",
      logo: "LOGO_JGTD.png",
      name: "JGT DUBA\n迪拜珠宝、宝石和技术展",
      desc: "Jewellery, Gem & Technology Dubai",
      url: "https://www.jgtdubaijewelleryshow.com/",
      group: "珠宝与时尚",
    },
    {
      time: "2025年11月15-17日",
      location: "迪拜",
      logo: "43_LOGO_MYPLANT DUBAI.png",
      name: "MYPLANT & GARDEN MIDDLE EAST\n迪拜国际植物与园艺展",
      desc: "International Exhibition dedicated to horticulture, landscape and technologies for the green industry",
      url: "",
      group: "绿色与科技",
    },
    {
      time: "2025年11月22-24日",
      location: "巴西 圣保罗",
      logo: "btff.png",
      name: "BTFF\n巴西健身展",
      desc: "BRASIL TRADING FITNESS FAIR",
      url: "https://btff.com.br/",
      group: "健身与运动",
    },
    {
      time: "2025年11月11-13日",
      location: "墨西哥",
      logo: "LOGO_ITM.png",
      name: "ITM Industrial Tranformation Mexico\n墨西哥智能制造工业展",
      desc: "Platform for digital transformation and Intelligent Manufacturing in Mexico",
      url: "https://industrialtransformation.mx/",
      group: "绿色与科技",
    },
    // 巴西门窗和框架展
    {
      time: "2026年9月",
      location: "巴西 圣保罗",
      logo: "LOGO_fesqua.png",
      name: "FESQUA\n巴西门窗和框架展",
      desc: "The Biggest and Most Important Event for the Frame Market in Latin America",
      url: "https://fesqua.com.br/",
      group: "绿色与科技",
    },
    {
      time: "2026年9月",
      location: "巴西 圣保罗",
      logo: "LOGO_EBRATS.png",
      name: "EBRATS\n巴西表面处理展",
      desc: "Brazilian Surface Treatment Encounter and Exposition",
      url: "https://www.ebrats.com.br/",
      group: "绿色与科技",
    },
    {
      time: "2026年9月",
      location: "意大利 里米尼会展中心",
      logo: "LOGO_tecna.png",
      name: "TECNA\n国际陶瓷技术展",
      desc: "International Exhibition of Technologies and Supplies for Surfaces",
      url: "https://en.tecnaexpo.com/",
      group: "绿色与科技",
    },
    {
      time: "2025年",
      location: "墨西哥",
      logo: "LOGO_MACS.png",
      name: "MEXICO ACTIVE & SPORTS EXPO\n墨西哥体育、健身和健康展",
      desc: "The leading platform for the sport, fitness and wellness industry in Latin America",
      url: "https://www.macsexpo.mx/",
      group: "健身与运动",
    },
  ],
};
