<template>
  <div class="home">
    <Header class="home-header" />
    <div class="home-body">
      <div class="home-body-header">
        <div class="home-body-left">
          我们在意大利和世界各地组织贸易展览会和会议。我们建立行业、关系、经验网络。我们关心地球。#COMMUNITYCATALYST
        </div>
        <img class="home-body-right" src="../assets/image17.png" />
      </div>
      <div class="home-body-video">
        <video
          src="https://ieg-expo.com.cn/ieg_mix_hp_sito_SEPT_2022.mp4"
          width="320"
          height="240"
          autoplay
          muted
          controls
          loop
        ></video>
      </div>
      <div class="home-body-desc">
        <div class="home-body-desc-item">
          <div class="home-body-desc-title">概述</div>
          <div class="home-body-desc-content">
            意大利展览集团Italian Exhibition
            Group在米兰上市，总部设在意大利，在世界各地组织国际展览会、活动和会议。作为IEG在中国的子公司，艾意吉展览（上海）有限公司整合意大利展览集团全球资源，助力中国企业拓宽国内外市场。
          </div>
          <a class="home-body-desc-more" href="/news/0">点击查看</a>
        </div>
        <a-divider type="vertical" style="height: 260px; border-color: #fff" />
        <div class="home-body-desc-item">
          <div class="home-body-desc-title">业务</div>
          <div class="home-body-desc-content">
            艾意吉展览（上海）有限公司的业务范围包括六大板块的相关展会，包括：食品和饮料、珠宝与时尚、健康与体育、旅游与酒店、生活方式与娱乐、绿色与技术。艾意吉展览致力于在国内举办品牌展会、组织中国企业拓宽海外市场，以及展馆运营、会议活动赛事组织、收购兼并等业务拓展。
          </div>
          <a class="home-body-desc-more" href="/news/business">点击查看</a>
        </div>
        <a-divider type="vertical" style="height: 260px; border-color: #fff" />
        <div class="home-body-desc-item">
          <div class="home-body-desc-title">新闻</div>
          <div class="home-body-desc-content">
            <a
              class="home-body-news"
              :href="`/news/template?id=${index + 1}`"
              :key="index"
              v-show="index < 4"
              v-for="(item, index) in config.news"
              >{{ item.title }}</a
            >
          </div>
          <a class="home-body-desc-more home-body-news" href="/news"
            >更多新闻</a
          >
        </div>
      </div>
      <div class="home-body-divider"></div>
      <div class="home-body-jt">
        <img class="home-body-jt-bg" src="../assets/ieg_alpha_logo.png" />
        <div class="home-body-jt-title">意大利展览集团</div>
        <div class="home-body-jt-desc">
          意大利展览集团（Italian Exhibition Group
          S.p.A.，缩写IEG）是一家在米兰泛欧交易所（Euronext
          Milan）上市的公司，该交易所是由意大利证券交易所（Borsa Italiana
          S.p.A.）组织和管理的受监管市场。多年来，IEG集团通过位于里米尼（Rimini）和维琴察（Vicenza）的展览机构而不断发展，在展览会和会议活动的组织方面居于意大利领先地位。它经营六条业务线（主办活动，大会活动，承办活动，相关服务，出版，体育活动）。通过在海外市场的不断拓展，以及和全球或当地主办的合作合资，IEG集团目前已在美国、阿拉伯联合酋长国、沙特阿拉伯、中国、墨西哥、德国、新加坡、巴西等国家设有子公司，跻身成为欧洲领先的会展集团之一。
          2023 年，IEG在里米尼和维琴察的展览和会议场馆综合体共组织或主办了 56
          场展览会和 126 场会议活动。
        </div>
        <div class="home-body-jt-ls">
          隶属于：<img
            src="https://www.iegexpo.it/images/homepage/logo_siso_jpg_no_margin.jpg"
            alt=""
          />
          <img
            src="https://www.iegexpo.it/images/homepage/ufi-logo.png"
            alt=""
          />
        </div>
        <div class="home-body-jt-card">
          <a
            class="home-body-jt-card-item"
            href="https://brochure.iegexpo.it/brochure/cover"
          >
            <div class="home-body-jt-card-title">概述</div>
            <img
              class="home-body-jt-card-img"
              src="https://www.iegexpo.it/templates/yootheme/cache/ieg_company_profile-ad141228.jpeg"
            />
          </a>
          <a-divider
            type="vertical"
            style="height: 194px; border-color: #ebebeb"
          />
          <a
            class="home-body-jt-card-item"
            href="https://www.iegexpomagazine.com/"
          >
            <div class="home-body-jt-card-title">杂志</div>
            <img
              class="home-body-jt-card-img"
              src="https://www.iegexpo.it/templates/yootheme/cache/immagine_IEG_EXPO_MAGAZINE_1200x800px-5bdf1cd1.jpeg"
            />
          </a>
          <a-divider
            type="vertical"
            style="height: 194px; border-color: #ebebeb"
          />
          <a
            class="home-body-jt-card-item"
            href="https://www.iegexpo.it/images/IEG_GREENCORE.pdf"
          >
            <div class="home-body-jt-card-title">IEG GREEN CORE</div>
            <img
              class="home-body-jt-card-img"
              src="https://www.iegexpo.it/images/ieg_green_core_051023.png"
            />
          </a>
          <a-divider
            type="vertical"
            style="height: 194px; border-color: #ebebeb"
          />
          <div class="home-body-jt-card-item">
            <div class="home-body-jt-card-title">证书</div>
            <img
              class="home-body-jt-card-img"
              src="https://www.iegexpo.it/templates/yootheme/cache/certificazioni_ieg_2023-e3de4470.png"
            />
            <a-dropdown>
              <div class="home-body-jt-card-btn">下载</div>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/pdf/ITALIAN_EXHIBITION_GROUP_SPA_ISO_14001-ITA.pdf"
                    >
                      Certificazione ISO 14001
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/pdf/ITALIAN_EXHIBITION_GROUP_SPA_ISO_45001_ITA.pdf"
                    >
                      Certificazione ISO 45001
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/iso/Italian_Exhibition_Group_Rimini_Expo_Centre_2022.pdf"
                    >
                      Certificazione GBAC Italian Exhibition Group
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/iso/Italian_Exhibition_Group_Palas_Rimini_2022.pdf"
                    >
                      Certificazione GBAC Palacongressi
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/iso/Italian_Exhibition_Group_Vicenza_Expo_Centre_2022.pdf"
                    >
                      Certificazione GBAC VICC
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/pdf/ISO_20121_ITA_VENUES.pdf"
                    >
                      Certificazione ISO 20121 Venues
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/pdf/ISO_20121_ITA_ECOMONDO.pdf"
                    >
                      Certificazione ISO 20121 Ecomondo
                    </a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
      </div>
      <div class="home-body-divider"></div>
      <div class="home-body-zh">
        <div class="home-body-zh-title">近期展会</div>
        <a class="home-body-zh-more" href="/exhibitions">了解更多</a>
        <div class="home-body-zh-list">
          <div
            class="home-body-zh-item"
            :key="exb.time"
            v-for="exb in exhibitions.slice(0, 9)"
          >
            <a class="home-body-zh-item-img" :href="exb.url">
              <img :src="require(`../assets/exhibitions/${exb.logo}`)" alt="" />
            </a>
            <a class="home-body-zh-item-title" :href="exb.url">{{
              exb.name
            }}</a>
            <div class="home-body-zh-item-content">
              {{ exb.location }}<br />{{ exb.time }}
            </div>
          </div>
        </div>
        <div class="home-body-zh-subtitle">官方指定承运商</div>
        <div class="home-body-zh-logo">
          <img src="https://www.iegexpo.it/images/logo_trenitalia.svg" />
        </div>
      </div>
      <div class="home-body-divider"></div>
      <div class="home-body-zg">
        <div class="home-body-zg-title">展馆</div>
        <div class="home-body-zg-content">
          <div class="home-body-zg-item">
            <a class="home-body-zg-item-title" href="/venues/rimini"
              >里米尼展览中心</a
            >
          </div>
          <div class="home-body-zg-item">
            <a
              class="home-body-zg-item-title"
              href="https://www.riminipalacongressi.it/"
              >里米尼会议中心</a
            >
          </div>
          <div class="home-body-zg-item">
            <a class="home-body-zg-item-title" href="/venues/vicenza/"
              >维琴察展览中心</a
            >
          </div>
          <div class="home-body-zg-item">
            <a
              class="home-body-zg-item-title"
              href="https://www.vicenzaconventioncentre.it/"
              >维琴察会议中心</a
            >
          </div>
        </div>
      </div>
      <div class="home-body-divider"></div>
    </div>
    <Footer class="home-footer" />
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import config from "@/config";
const exhibitions = config.exhibitions;
</script>
<style lang="scss">
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-header {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100px;
    background-color: #fff;
  }
  &-body {
    width: 1200px;
    &-header {
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
    }
    &-left {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      width: 308px;
      height: 72px;
    }
    &-right {
      width: 255.14px;
      height: 96px;
    }
    &-news {
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
    &-video {
      height: 400px;
      display: flex;
      align-items: center;
      overflow: hidden;
      video {
        height: 788px;
        width: 1200px;
      }
    }
    &-desc {
      height: 280px;
      display: flex;
      align-items: center;
      margin: 20px 0 40px;
      &-item {
        flex: 1;
        padding: 20px 32px;
        position: relative;
        text-align: left;
        height: 280px;
        background: #f7f7f7;
      }
      &-title {
        color: #000;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        height: 26px;
      }
      &-content {
        color: #343434;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 16px 0;
      }
      &-more {
        color: #343434;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        position: absolute;
        left: 32px;
        bottom: 20px;
      }
    }
    &-jt {
      padding-top: 40px;
      position: relative;
      &-title {
        color: var(---001, #1a1a1a);
        font-family: PingFang SC;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
      }
      &-desc {
        overflow: hidden;
        color: #343434;
        text-align: center;
        text-overflow: ellipsis;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 24px 0;
      }
      &-bg {
        position: absolute;
        width: 148.8px;
        height: 148px;
        top: 40px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
      }
      &-ls {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        img {
          height: 20px;
          margin-left: 10px;
        }
      }
      &-card {
        margin: 30px 0 40px;
        display: flex;
        justify-content: center;
        &-item {
          width: 270px;
          height: 196px;
          padding: 0 20px;
          position: relative;
        }
        &-title {
          color: #343434;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
        }
        &-img {
          width: 230px;
          height: 153.333px;
          margin-top: 16px;
        }
        &-btn {
          font-size: 12px;
          padding: 0 12px;
          height: 24px;
          border: 1px solid rgba(255, 255, 255, 0.9);
          color: #343434;
          position: absolute;
          border-radius: 4px;
          cursor: pointer;
          bottom: 20px;
          left: 105px;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
    }
    &-zh {
      margin: 30px 0 40px;
      padding: 60px 40px 0;
      text-align: left;
      position: relative;

      &::before {
        content: " ";
        display: block;
        width: 300px;
        background: url(https://www.iegexpo.it/templates/yootheme_iegexpo/images/x-iegexpo-icon-section-background.png)
          no-repeat;
        background-size: contain;
        position: absolute;
        left: calc(600px - 50vw);
        top: 0px;
        bottom: 0;
        z-index: -1;
      }
      &-title {
        color: var(---001, #1a1a1a);
        font-family: PingFang SC;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px;
      }
      &-more {
        display: block;
        color: var(---001, #1a1a1a);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        width: 96px;
        height: 32px;
        border: 2px solid #2d2e33;
        text-align: center;
        cursor: pointer;
        margin: 18px 0 36px;
      }
      &-list {
        width: 1200px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      &-item {
        position: relative;
        padding-left: 108px;
        height: 160px;
        display: flex;
        flex-direction: column;
        &-img {
          left: 0;
          width: 100px;
          height: 100px;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #000;
          overflow: hidden;
          z-index: 0;
          img {
            width: 100px;
          }
        }
        &-title {
          width: 265px;
          font-size: 20px;
          line-height: 1.4;
          font-family: RalewayIEG;
          font-weight: 700;
          text-transform: uppercase;
        }
        &-content {
          font-family: RalewayIEG;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
        &-desc {
          font-family: RalewayIEG;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
        }
      }
      &-subtitle {
        font-family: RalewayIEG;
        font-size: 16px;
        line-height: 1.4;
        font-family: RalewayIEG;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        margin-bottom: 20px;
      }
      &-logo {
        text-align: center;
        img {
          width: 200px;
          height: 64px;
        }
      }
    }
    &-zg {
      &-title {
        color: var(---001, #1a1a1a);
        font-family: PingFang SC;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px;
        margin: 40px 0;
        text-align: left;
      }
      &-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
      }
      &-item {
        width: 292px;
        height: 195px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(1) {
          background: url(https://www.iegexpo.it/templates/yootheme/cache/venue_rimini-a4203943.jpeg)
            no-repeat;
          background-size: contain;
        }
        &:nth-child(2) {
          background: url(https://www.iegexpo.it/templates/yootheme/cache/venue_vicenza-9ce3d544.jpeg)
            no-repeat;
          background-size: contain;
        }
        &:nth-child(3) {
          background: url(https://www.iegexpo.it/templates/yootheme/cache/palacongressi-rimini-6a5431e6.jpeg)
            no-repeat;
          background-size: contain;
        }
        &:nth-child(4) {
          background: url(https://www.iegexpo.it/templates/yootheme/cache/palacongressi-vicenza-092c1279.jpeg)
            no-repeat;
          background-size: contain;
        }
        &-title {
          border: 2px solid var(---000, #fff);
          background: var(--00260, rgba(0, 0, 0, 0.6));
          width: 180px;
          padding: 1px 16px;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          color: var(---000, #fff);

          &:visited {
            color: #fff;
          }
          &:hover {
            background: var(--00260, #fff);
            color: #343434;
          }
        }
      }
    }
    &-divider {
      height: 1px;
      background-color: #bfbfbf;
      width: 100vw;
      position: relative;
      left: calc(600px - 50vw);
    }
  }
}

@media (max-width: 768px) {
  .home-body {
    width: 100vw;
  }
  .home-body-header {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-body-left {
    width: 100vw;
    padding: 0 15px;
  }
  .home-body-right {
  }
  .home-body-video {
    padding: 0 15px;
    width: 100vw;
    height: 200px;
    video {
      width: 100%;
      height: 200px;
    }
  }
  .home-body-desc {
    flex-direction: column;
    height: 900px;
    .ant-divider {
      height: 10px !important;
    }
  }
  .home-body-jt-desc {
    padding: 0 15px;
  }
  .home-body-jt-card {
    flex-direction: column;
    margin-bottom: 10px;
    .ant-divider {
      display: none;
    }
  }
  .home-body-jt-card-img {
    width: 345px;
    height: auto;
    margin-bottom: 10px;
  }
  .home-body-jt-card-item {
    height: auto;
  }
  .home-body-jt-card-btn {
    width: 100px;
    height: 30px;
    justify-content: center;
    font-size: 13px;
    background-color: #fff;
    left: 135px;
    bottom: 100px;
    box-shadow: 0px 0px 1px 1px #e0e0e0;
  }
  .home-body-zh-list {
    display: flex;
    flex-direction: column;
  }
  .home-body-zh {
    padding: 0 15px;
    margin-bottom: 0;
  }
  .home-body-zh-title {
    font-size: 20px;
  }
  .home-body-zh-more {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  .home-body-zh-item {
    width: 345px;
    display: flex;
    flex-direction: column;
    height: 120px;
  }
  .home-body-zh-item-title {
    width: 230px;
  }
  .home-body-zh-subtitle {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .home-body-zh-logo {
    height: 64px;
    display: flex;
    justify-content: center;
    img {
      display: block;
      height: 30px;
    }
  }
  .home-body-zg {
    padding: 0 15px;
  }
  .home-body-zg-title {
    font-size: 20px;
    margin: 10px 0;
  }
  .home-body-zg-content {
    flex-direction: column;
  }
  .home-body-zg-item {
    width: 345px;
    background-size: cover !important;
    margin-bottom: 10px;
  }
}
</style>
